import './App.css'
import React from 'react';
import DataPanel from '../components/DataPanel/DataPanel';

function Velocity() {
    return (
        <div className="App">
          <header className="App-header">
            <DataPanel />
          </header>
        </div>
      );
}

export default Velocity;