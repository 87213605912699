const names = [
    "Alex Borowski",
    "Alex Emery",
    "Calvin Hsu",
    "Danny Cravioto-Ross",
    "Darik Feasey",
    "Dawson Pratt",
    "Gavin Moes",
    "Jesse Umoren",
    "Kieran Robinson-Dunning",
    "Logan Greves",
    "Mason Greves",
    "Nick Johnson",
    "Nik Reimer",
    "Owen Lam",
    "Reeve Gingera",
    "Ryder Turner",
    "Toby Bergh"
];

export default names;