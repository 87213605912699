import '../../styles/DataPanel.css';
import logo from '../../logo.svg';
import React, { useState, useEffect } from 'react';
import names from '../CapturePanel/NameList.js';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//const NAMES = ['Ryder Turner', 'Reeve Gingera', 'Logan Greves', 'Toby Bergh', 'Gavin Moes', 'Owen Lam', 'Mason Greves', 'Jesse Umoren', 'Nick Johnson', 'Danny Cravioto-Ross', 'Nik Reimer', 'Alex Emery', 'Dawson Pratt', 'Calvin Hsu', 'Alex Borowski', 'Darik Feasey', 'Kieran Robinson-Dunning', ]

// create list of months
const monthRange = (start, end) => {
    const result = [];
    let [startYear, startMonth] = start.split('-').map(Number);
    const [endYear, endMonth] = end.split('-').map(Number);

    while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
        const formattedMonth = String(startMonth).padStart(2, '0');
        result.push(`${startYear}-${formattedMonth}`);

        // Increment month
        startMonth++;
        if (startMonth > 12) {
            startMonth = 1;
            startYear++;
        }
    }

    return result;
}
const currentDate = new Date();
const MONTHS = monthRange('2024-09', currentDate.toISOString().slice(0, 7));

const API_URL = process.env.REACT_APP_API_URL;
const MIN_SAMPLE_SIZE = 6;

const cache = {};

const DataPanel = () => {

    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [selectedServeType, setSelectedServeType] = useState('');
    const [selectedStartMonth, setSelectedStartMonth] = useState(MONTHS[0]);
    const [selectedEndMonth, setSelectedEndMonth] = useState(MONTHS[MONTHS.length - 1]);
    const [filterSample, setFilterSample] = useState(true);
    const [rawServeData, setRawServeData] = useState([]);
    const [serveData, setServeData] = useState([]);
    const [serveCount, setServeCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                <p className="sample-label">{`sample size: ${payload[0].payload.serve_count}`}</p>
                <p className="eps-label">{`ePS: ${payload[0].payload.mean_outcome.toFixed(0)}%`}</p>
                <p className="err-label">{`Err: ${payload[0].payload.error_rate.toFixed(0)}%`}</p>
                </div>
            );
        }
        return null;
      };

    const postData = async (selectedOption) => {
        try {
            const response = await fetch(API_URL + "/velocities/getbuckets", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    player: selectedPlayer,
                    serve_times: monthRange(selectedStartMonth, selectedEndMonth),
                    serve_type: selectedServeType
                })
            });
            if (response.status === 200) {
                const data = await response.json();
                setRawServeData(() => (data.body));
                console.log(data.body);
                cache[selectedOption] = data.body;
            }
        } catch (error) {
            console.error('Error:', error); // Handle any errors
        }
    };

    useEffect(() => {
        setServeCount(() => (serveData.reduce((a,c) => {return a+c.serve_count}, 0)))
    }, [serveData]);

    useEffect(() => {
        const updateData = async () => {
            if (selectedPlayer && selectedServeType && selectedStartMonth && selectedEndMonth) {
                const selectedOption = `${selectedPlayer} - ${selectedServeType} - ${selectedStartMonth} - ${selectedEndMonth}`;
                if (cache[selectedOption]) {
                    setRawServeData(cache[selectedOption]);
                } else {
                    await postData(selectedOption);
                }
            }
            setLoading(false);
        }
        setLoading(true);
        updateData();
      }, [selectedPlayer, selectedServeType, selectedStartMonth, selectedEndMonth]);

    useEffect(() => {
        if (filterSample) {
            setServeData(rawServeData.filter(serve => serve.serve_count >= MIN_SAMPLE_SIZE));
        } else {
            setServeData(rawServeData);
        }
    }, [rawServeData, filterSample]);

    return (
        <div className="App">
            <img src={logo} alt="UBC Thunderbirds" className="app-logo"></img>
            <h1 className="title">Velocity Data</h1>
            <div className='filter-area'>
                <div className='player-filter'>
                    <label htmlFor="player">Player:</label>
                    <select id="player" value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.target.value)}>
                        <option value="" disabled>Select an option</option>
                        {names.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>

                <div className='serve-type-filter'>
                    <label htmlFor="serve-type">Serve Type:</label>
                    <select id="serve-type" value={selectedServeType} onChange={(e) => setSelectedServeType(e.target.value)}>
                        <option value="" disabled>Select an option</option>
                        <option value="FLOAT">Float</option>
                        <option value="SPIN">Spin</option>
                        <option value="HYBRID">Hybrid</option>
                    </select>
                </div>
                <div className='flex-break'/>
                <div className='dates'>
                    <label htmlFor="start-month">Date Range:</label>
                    <select id="start-month" value={selectedStartMonth} onChange={(e) => setSelectedStartMonth(e.target.value)}>
                        <option value="" disabled>Select an option</option>
                        {MONTHS.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                    <label htmlFor="start-month">-</label>
                    <select id="end-month" value={selectedEndMonth} onChange={(e) => setSelectedEndMonth(e.target.value)}>
                        <option value="" disabled>Select an option</option>
                        {MONTHS.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className='flex-break'/>
                <div className='filter'>
                    <input type="checkbox" id="filter-sample" onChange={() => setFilterSample(!filterSample)} checked={filterSample} />
                    <label htmlFor="filter-sample" className='filter-label'>Hide Low Sample Size Points</label>
                </div>
            </div>
      
            <p className='serve-count'>{loading ? "..." : serveCount + " serves"}</p>
            
            <div className='chart-area'>
                {serveData.length > 0 &&
                <ResponsiveContainer width={"100%"} height={500}>
                    <LineChart
                        width="100%"
                        data={serveData}
                        margin={{
                            top: 5,
                            right: 0,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis stroke="#ddd" dataKey="bucket_velocity" label={{ value: 'Velocity (kph)', position: 'bottom' }} />
                    <YAxis stroke="#ddd" domain={[0, 100]} label={{ value: '%', angle: -90, position: 'left' }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend layout="horizontal" verticalAlign="top" align="right" />
                    <Line type="monotone" strokeWidth={3} dataKey="mean_outcome" stroke="#eaa22e" name="ePS" />
                    <Line type="monotone" dataKey="error_rate" stroke="#ccc" name="Error rate" />
                    </LineChart>
                </ResponsiveContainer>}
            </div>

        </div>
    )

};

export default DataPanel;